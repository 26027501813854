import React from 'react';
import '../../App.css';
import '../Services.css';
import Footer from '../Footer';

export default function Services() {
  return (<>
    
    <div className='service-container'>
          
    </div>

    <h1>
      We're bringing innovative community based interactive forums
    </h1>
    <h2>

      We are going to redefine financial inclusion and alignment


    </h2>

    <Footer /> 
    
  </>
);
}