// src/components/PartnerLogos.js
import React from 'react';
import './PartnerLogos.css';


function PartnerLogos () {    

    return (
        <div className='partner-logos'>
        <h2 style={{color: 'white', textAlign:'left'}}> Our Partners</h2>
        <div className="partner-logos">
           <div className= "logo-container" >
                <img src='../images/craddlelog.png' alt='Partners Logo' className='partner-logo'/>
            </div>
            <div className= "logo-container" >
                <img src='../images\oldmut.png' alt='Partners Logo' className='partner-logo'/>
            </div>
            <div className= "logo-container" >
                <img src='../images\remote.png' alt='Partners Logo' className='partner-logo'/>
            </div>
        </div>
        </div>
    );
};

export default PartnerLogos;
