import React, { useState } from 'react';
import './VideoCard.css';
import VideoItem from './VideoItem';
import VideoPlayer from './VideoPlayer';

const VideoCard = () => {

    const videos = [
        {
          id: 1,
          title: '"From the streets to an establishment"',
          src: 'videos/Testimony3.mp4',
          thumbnail: 'images/Thumbnail2.png',
        },
        {
          id: 2,
          title: '"Yemaka is here for you"',
          src: 'videos/Testimony2.mp4',
          thumbnail: 'images/Th.png',
        },
        // Add more videos here
      ];

    const [selectedVideo, setSelectedVideo] = useState(null);
  
    const handleVideoClick = (video) => {
      setSelectedVideo(video);
    };
  
    const handleCloseVideo = () => {
      setSelectedVideo(null);
    };
  
    return (
      <div className="video">
        <h1 style={{ color: 'orange', fontWeight: 'bold' }}>YE! Testimonies!</h1>
        <div className="video-list">
          {videos.map((video) => (
            <VideoItem key={video.id} video={video} onClick={handleVideoClick} />
          ))}
        </div>
        {selectedVideo && (
          <VideoPlayer video={selectedVideo} onClose={handleCloseVideo} />
        )}
      </div>
    );
  };
  
  export default VideoCard;