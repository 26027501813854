import React from 'react';
import { useState } from 'react';
import './ContactForm.css'; 
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
    const navigate = useNavigate();
    const initialFormState = {
      
        name: '',        
    
    };
  
    const [formData, setFormData] = useState(initialFormState);
    const [formDataOne, setFormDataOne] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    const handleSelectChange = (event) => {
      setFormDataOne(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString()
        })
        .then(() => alert('Form successfully submitted \nReturning you to the home page'))
        .catch((error) => alert('Error submitting form: ' + error));
        setFormData(initialFormState);
        setShowModal(true);
        navigate('/');
    };
    const handleOkClick = () => {
      setShowModal(false);
      navigate('/'); // Navigate to home page
    };

  return (
    <div className="contact-form-container">
      <div className="form-section">
        <form onSubmit={handleSubmit} name="contact" method="POST" data-netlify="true">
          <div className="form-group">            
            <input type="text" name="name" value={formData.name} placeholder='Name*' onChange={handleChange} required />
          </div>

          <div className= 'form-group'>
          <select name="Yemaka-Status" value={formDataOne} onChange={handleSelectChange} required>
            <option value="" disabled> -- select an option -- </option>
            <option value="Borrower">Borrower</option>
            <option value="Lender">Lender</option>
            <option value="Both">Both</option>
        </select>
        </div>

        {(formDataOne === "Borrower") && (<div className="form-group">
                <div className='form-group'>                
                <input type="text" name="Amount_Requested" placeholder='How much do you need*' required/>
                </div>

                <div className='form-group'>                
                <input type="text" name="Borrow_Timeline" placeholder='How long do you need the money*' required/>
                </div>

                <div className='form-group'>                
                <select name="Profession" id="Professional_Status" required>
                    <option disabled selected value> Profession? -- select an option -- </option>
                    <option value="Employee">Employee</option>
                    <option value="Self Employed/Entrepreneur">Self Employed/Entrepreneur</option>
                    <option value="Student">Student</option>
                </select>
                </div>
                
                <select name="Income" id="Income_Range" required>
                    <option disabled selected value>How much is your monthly income? -- select an option -- </option>
                    <option value="Below 50k">Below &#x20A6;50,000</option>
                    <option value="50k - 100k">&#x20A6;50,000 - &#x20A6;100,000</option>
                    <option value="100k - 250k">&#x20A6;100,000 - &#x20A6;250,000</option>
                    <option value="250k - 500k">&#x20A6;250,000 - &#x20A6;500,000</option>
                    <option value="Above 500,000">Above &#x20A6;500,000</option>
                </select>
                
                <input type="text" name="Location" placeholder='Location*' required />
            </div>)}
            {(formDataOne === "Lender") && ( <div class="form-group">

            
            <select name="lendrange" id="Lend_Range" required >
                <option disabled selected value>How much do you want to lend? -- select an option -- </option>
                <option value="Below 50k">Below &#x20A6;50,000</option>
                <option value="50k - 100k">&#x20A6;50,000 - &#x20A6;100,000</option>
                <option value="100k - 250k">&#x20A6;100,000 - &#x20A6;250,000</option>
                <option value="250k - 500k">&#x20A6;250,000 - &#x20A6;500,000</option>
                <option value="Above 500,000">Above &#x20A6;500,000</option>
            </select>

            
            <select name="loanduration" id="Loan_Duration" required>
                <option disabled selected value> How long can you lend?  -- select an option -- </option>
                <option value="1 Month">1 Month</option>
                <option value="2 Months">2 Month</option>
                <option value="3 Months">3 Months</option>
            </select>

            
            <input type="text" name="Location" placeholder='Location*' required/>
            </div>
            )}

            {(formDataOne === "Both") && (<div class="form-group">
                
                <input type="text" name="Amount_Requested" placeholder='How much do you need*' required />

                
                <input type="text" name="Borrow_Timeline" placeholder='How long do you need the money*' required />

                
                <select name="Profession" id="Professional_Status" required>
                    <option disabled selected value> Profession? -- select an option -- </option>
                    <option value="Employee">Employee</option>
                    <option value="Self Employed/Entrepreneur">Self Employed/Entrepreneur</option>
                    <option value="Student">Student</option>
                </select>

                
                <select name="Income" id="Income_Range" required >
                    <option disabled selected value>How much is your monthly income? -- select an option -- </option>
                    <option value="Below 50k">Below &#x20A6;50,000</option>
                    <option value="50k - 100k">&#x20A6;50,000 - &#x20A6;100,000</option>
                    <option value="100k - 250k">&#x20A6;100,000 - &#x20A6;250,000</option>
                    <option value="250k - 500k">&#x20A6;250,000 - &#x20A6;500,000</option>
                    <option value="Above 500,000">Above &#x20A6;500,000</option>
                </select>
                
                
                <div class="divider_bothoptions"></div>

                
                <select name="lendrange" id="Lend_Range" required >
                    <option disabled selected value> How much do you want to lend?  -- select an option -- </option>
                    <option value="Below 50k">Below &#x20A6;50,000</option>
                    <option value="50k - 100k">&#x20A6;50,000 - &#x20A6;100,000</option>
                    <option value="100k - 250k">&#x20A6;100,000 - &#x20A6;250,000</option>
                    <option value="250k - 500k">&#x20A6;250,000 - &#x20A6;500,000</option>
                    <option value="Above 500,000">Above &#x20A6;500,000</option>
                </select>

                
                <select name="loanduration" id="Loan_Duration" required >
                    <option disabled selected value>How long can you lend? -- select an option -- </option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Month</option>
                    <option value="3 Months">3 Months</option>
                </select>

                
                <input type="text" name="Location" placeholder='Location* ' required />
            </div>)}

          
          <button type="submit" className="form-button">Submit</button>

        </form>
        
        {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Form submitted successfully!</p>
            <button onClick={handleOkClick}>OK</button>
          </div>
        </div>
      )}
      </div>
        <div className="contact-info">
          <div className="contact-item">
            <i className= "fas fa-envelope" />
            <span>info@yemaka.app</span>
          </div>
          <div className="contact-item">
            <i className= "fas fa-phone" />            
            <span>+234 703 643 6974</span>
          </div>
          <div className="contact-item">
            <i className= "fas fa-map-marker-alt" />            
            <span>
              Work Cradle 4th Floor Banex Mall <br></br> 
              off Oniru road, Lekki, Lagos.
            </span>
          </div>
        </div>
    </div>
  );
};

export default ContactForm;

