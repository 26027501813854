import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ResponsiveSection from '../../ResponsiveSection';

export default function Products() {
  return (<>
    <div>
      <ResponsiveSection />   
      
    </div>
    <Footer />
  </>
)
  
}