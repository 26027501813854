import React from 'react';
import './Floater.css';

export default function Floater () {
    return(<div class="float_bg">
    <div onclick="window.open('tel: +2347036436974')"> 
        <a href={`tel:${+2347036436974}`}>
        <img src="images/phone-icon.svg" class="float_img" alt='contact number'/> Get in touch: +234 703 643 6974
        </a> 
    </div>
</div>);
}
