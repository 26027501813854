import React from 'react';
import './IconList.css'

const icons = [
  { src: '/images/clock.png', title: 'Lend', description: 'Lend to borrowers  across various demography with the best credit worthiness scrutinized and verified by us.' },
  { src: '/images/commune.png', title: 'Personalized Loans', description: 'Get loans which suit your credit bracket with favorable repayment plans.' },
  { src: '/images/save.png', title: 'Save', description: 'Save money  on  the Yemaka platform at favourable interest rates' },
  { src: '/images/security.png', title: 'Security', description: 'For whatever endeavor you choose to be a part of on the Yemaka platform, we provide security and ease.' },
  // Add more icons as needed
];

function IconList() {
  return (
    <div className="App">
      <h1 style={{color: 'orange'}}>
        Exclusive Benefits
      </h1>
      <div className="icon-list">
        {icons.map((icon, index) => (
          <div className="icon-item" key={index}>
            <img src={icon.src} alt={icon.title} className="icon-image" />
            <h3 className="icon-title">{icon.title}</h3>
            <p className="icon-description">{icon.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default IconList;
