import React from 'react';
import './ResponsiveSection.css';

const ResponsiveSection = () => {
  return (
    <div className="responsive-section">
      <div className="content">
        <h2> Safety For Lenders</h2>
        <img src="images\coin stack.png" alt="Example" className="image" />
        <p>
        We offer a good platform for lending money by ensuring security and efficiency. 
        Our platform provides a robust risk assessment system to safeguard
        lenders. Transparent terms and easy-to-understand processes build 
        trust and confidence between us and our investors (lenders).
        We hope to create an intuitive interface  to enhance user experience, 
        while efficient transaction processing and customer support ensure seamless operations.
        We are also Integrating advanced technologies like AI for credit scoring and, subsequently, 
        blockchain for secure transactions to further enhance reliability and innovation, 
        making the platform a trusted choice for financial transactions.
        </p>
      </div>
      <div className="content">
        <h2> Platform For Borrowers</h2>
        <img src="images\Pile.png" alt="Example" className="image" />
        <p>
        Our platform provides borrowers with a streamlined and user-friendly experience to secure loans at favorable rates. By leveraging advanced algorithms and a vast network of lenders, we ensure competitive interest rates tailored to each borrower's profile. Transparent processes, minimal paperwork, and quick approvals make borrowing easy and efficient. We prioritize financial inclusivity, offering personalized support to help borrowers find the best loan options suited to their needs, fostering financial growth and stability.
        </p>
        
      </div>
      <div className="content">
        <h2> Earn as You Save</h2>
        <img src="images\Jar.png" alt="Example" className="image" />
        <p>
        Our platform provides an exceptional opportunity to save money and earn competitive interest rates. With user-friendly features and security, we ensure your savings grow efficiently. Enjoy the benefits of high-interest rates, easy account management, and no hidden fees. Start saving smarter today and watch your money work harder for you. Join us for a financially secure future.
        </p>
        
      </div>

      <div className="content">
        <h2> The Delight of Community</h2>
        <img src="images\communitytwo.jpg" alt="Example" className="image" />
        <p>
        We're bringing innovative community based interactive forums. We are going to redefine financial inclusion and alignment, creating a world where all is possible.
        </p>
        
      </div>
    </div>
  );
};

export default ResponsiveSection;
