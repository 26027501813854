import React from 'react';
import './Footer.css';
/*import { Button } from './Button';*/
import { Link } from 'react-router-dom';
import logo from '../logo.png';

function Footer() {
  return (
    <div className='footer-container'>      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/products'>How it works</Link>
            <Link to='/'>Testimonies</Link>            
            <Link to='/sign-up'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/sign-up'>Contact</Link>
            <Link to='/sign-up'>Support</Link>         
            
          </div>
        </div>
        <div className='footer-link-wrapper'>          
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.instagram.com/yemaka.app?igsh=MWJyZnM2ZHZleHE1dQ%3D%3D&utm_source=qr'>Instagram</Link>
            <Link to='https://www.facebook.com/profile.php?id=100078034389979&mibextid=9R9pXO'>Facebook</Link>
            <Link to='https://www.linkedin.com/company/yemaka-technologies-limited/?viewAsMember=true'>LinkedIn</Link>
            <Link to='https://x.com/yemakaapp?s=11&t=ucqw3AlhY5H9D-2Rovp_Zg'>X</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src={logo} alt="Logo" />
            </Link>
          </div>
          <small class='website-rights'>© 2020</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/profile.php?id=100078034389979&mibextid=9R9pXO'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/yemaka.app?igsh=MWJyZnM2ZHZleHE1dQ%3D%3D&utm_source=qr '
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>            
            <Link
              class='social-icon-link twitter'
              to='https://x.com/yemakaapp?s=11&t=ucqw3AlhY5H9D-2Rovp_Zg'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='https://www.linkedin.com/company/yemaka-technologies-limited/?viewAsMember=true'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;