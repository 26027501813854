import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Videocard from '../VideoCard';
import IconList from '../IconList';
import PartnerLogos from '../PartnerLogos';
import TestimonialCards from '../TestimonialCards';





function Home() {
  return (
    <>
      <HeroSection />      
      <IconList />  
      <PartnerLogos />                           
      <Videocard />
      <TestimonialCards />
      <Footer />
      
      
    </>
  );
}

export default Home;