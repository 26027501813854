import React from 'react';
import '../../App.css';
/*import NetlifyForm from '../NetlifyForm';*/
import Footer from '../Footer';
import ContactForm from '../ContactForm';

export default function SignUp() {
  return(<>
     
    <ContactForm />   
    <Footer /> 
  </>);
}
   