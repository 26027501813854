import React from 'react';
import Testimonial from './Testimonials';
import '../App.css';

const testimonialsData = [
  {
    text: 'Truly an exceptional service.  Yemaka’s process is straightforward and fast. They are my go-to for loans always, and they have played a big part in helping my business grow. \n\nBest Regards,'
    ,
    author: 'Edosa Obaseki'
  },
  {
    text: 'I can\'t thank Yemaka enough for being my financial lifeline. \nTheir same-day loan service came through when I needed it the most, helping me sort out urgent matters with ease. As an investor, the 36% return on my funds surpassed expectations. Yemaka\'s platform is not just user-friendly; it\'s a wealth-building gem. Quick responses from their support team add a layer of trust that sets Yemaka apart.In a nutshell, Yemaka is my go-to for immediate financial needs and impressive investment returns. \n\nCheers,'
    ,
    author: 'Jane Smith'
  },
  {
    text: 'Well done Yemaka!!!!   I will recommend Yemaka as the most preferred loan app because it has: \n-Easy and fast disbursement less than 24hrs.    \n- Flexibility of payment structure    - Good customer service.   - low interest rate.'
    ,
    author: 'Akinola Owolabi'
  }
];

function TestimonialCards() {
  return (
    <div className='app'>
      <h1>Testimonials</h1>
      <div className="testimonials-container">
        {testimonialsData.map((testimonial, index) => (
          <Testimonial 
            key={index} 
            text={testimonial.text} 
            author={testimonial.author} 
          />
        ))}
      </div>
    </div>
  );
}

export default TestimonialCards;
