// App.js
import React from 'react';
import './VideoCard.css';





const VideoPlayer = ({ video, onClose }) => {
  return (
    <div className="video-player">
      <button className="close-button" onClick={onClose}>X</button>
      <video controls autoPlay>
        <source src={video.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h2>{video.title}</h2>
    </div>
  );
};

export default VideoPlayer