import React, { useState } from 'react';
import './Testimonials.css';

const Testimonial = ({ text, author }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <div
      className={`testimonial ${isClicked ? 'clicked' : ''}`}
      onClick={handleClick}
    >
      <p style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>"{text}"</p>
      <h4>- {author}</h4>
    </div>
  );
};

export default Testimonial;
