import React from 'react';
import './VideoCard.css';

const VideoItem = ({ video, onClick }) => {
        
    return (
      <div className="video-item" onClick={() => onClick(video)}>
        <img src={video.thumbnail} alt={video.title} className="thumbnail" />
        <h3>{video.title}</h3>  
        <button className="play-button">Play</button>      
      </div>
    );
  };

  export default VideoItem;